import React, {Component} from 'react';
import Select from 'react-select'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './App.scss';
import {
    ReactiveBase,
    CategorySearch,
    ReactiveList,
    MultiDropdownList,
    ToggleButton,
    ReactiveComponent,
    SelectedFilters,
    DynamicRangeSlider,
    RangeSlider
} from '@appbaseio/reactivesearch';


/**
 * Dispatch call to jQuery
 *
 * @param id
 */
function dispatchModal(id) {
    window.getSingleItemES(id);
}

let currentLang = 'SV';

if (document.getElementById('root-es')){
    currentLang = document.getElementById('root-es').getAttribute('data-lang');
}

const options = [
    {value: 'sort.raw', label: 'Standard'},
    {value: 'upload.raw', label: 'Nyaste'},
    {value: 'benamn1.raw', label: 'Produktnamn'},
    {value: '_score', label: 'Bästa match'},
]

class App extends Component {


    /**
     * Adds a badge to show type of result in dropdown
     *
     * @param value
     * @param object
     * @returns {string}
     */
    getHighlightField(value, object) {

        let key = Object.keys(object).find(key => object[key] === value);

        if (key === 'designer') {
            return this.strings['designer-label'];
        }

        if (key === 'designer_poplist') {
            return this.strings['designer-label'];
        }

        if (key === 'varumarke') {
            return  this.strings['brand-label'];
        }

        if (key === 'benamn1') {
            return  this.strings['sort-name'];
        }

        if (key === 'underkategori_ngram') {
            return this.strings['subcategory-label'];
        }
        if (key === 'huvudkategori') {
            return this.strings['category-label'];
        }

        return key;
    }

    // Change sort to best match
    setBestMatchFilterOnChange() {
        if (this.state.sortBy.value === 'sort.raw') {
            this.updateSorting({value: '_score', label: 'Bästa match'})
        }
    }

    // Set new sort field
    updateSorting(newSort) {
        console.log(newSort);
        this.setState({
            sortBy: newSort,

        });
    }

    // Update number of results in state
    updateNumberOfResults(results) {
        this.setState({
            numberOfResults: results,
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            numberOfResults: 10000,
            sortBy: options[0],
            sortOrder: 'desc',
            language: document.getElementById('root-es').getAttribute('data-lang'),
            selectValue: {value: 'upload.raw', label: 'Nyaste'}
        }

        if(currentLang === 'SV') {
            this.strings = {
                'search-label': 'Sök bland våra produkter',
                'search-short-label': 'Sök',
                'all-label': 'Alla',
                'brand-label': 'Varumärke',
                'designer-label': 'Designer',
                'length-label': 'Längd',
                'width-label': 'Bredd/Djup',
                'height-label': 'Höjd',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljömärkningar',
                'filter-label': 'Filter',
                'filter-classic': 'Klassiker',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnamn',
                'sort-new': 'Nyast',
                'sort-best': 'Bästa match',
                'search-in-prefix': 'Sök efter',
                'search-in-suffix': 'i alla kategorier'
            }
        }

        if(currentLang === 'EN') {
            this.strings = {
                'search-label': 'Search among our products',
                'search-short-label': 'Search',
                'all-label': 'All',
                'brand-label': 'Brand',
                'designer-label': 'Designer',
                'length-label': 'Length',
                'width-label': 'Width / Depth',
                'height-label': 'Height',
                'category-label': 'Category',
                'subcategory-label': 'Subcategory',
                'enviroment-label': 'Environmental labels',
                'filter-label': 'Filter',
                'filter-classic': 'Classic',
                'sort-label': 'Sort by',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnamn',
                'sort-new': 'Newest',
                'sort-best': 'Best match',
                'search-in-prefix': 'Search for',
                'search-in-suffix': 'in all categories'
            }
        }

        if(currentLang === 'DA') {
            this.strings = {
                'search-label': 'Søg blandt vores produkter',
                'search-short-label': 'Søg',
                'all-label': 'Alle',
                'brand-label': 'Varemærke',
                'designer-label': 'Designer',
                'length-label': 'Længde',
                'width-label': 'Bredde/Dybde',
                'height-label': 'Højde',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljømærker',
                'filter-label': 'Filter',
                'filter-classic': 'Klassiker',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnavn',
                'sort-new': 'Nyeste',
                'sort-best': 'Bedste match',
                'search-in-prefix': 'Søg efter',
                'search-in-suffix': 'i alle kategorier'
            }
        }


        if(currentLang === 'NB') {
            this.strings = {
                'search-label': 'Søk blant våre produkter',
                'search-short-label': 'Søk',
                'all-label': 'Alle',
                'brand-label': 'Varemerke',
                'designer-label': 'Designer',
                'length-label': 'Lengde',
                'width-label': 'Bredde / Dybde',
                'height-label': 'Høyde',
                'category-label': 'Kategori',
                'subcategory-label': 'Underkategori',
                'enviroment-label': 'Miljømerker',
                'filter-label': 'Filter',
                'filter-classic': 'Klassiker',
                'sort-label': 'Sortering',
                'sort-standard': 'Standard',
                'sort-name': 'Produktnavn',
                'sort-new': 'Nyeste',
                'sort-best': 'Beste treff',
                'search-in-prefix': 'Søk etter',
                'search-in-suffix': 'i alle kategorier'
            }
        }

        if(currentLang === 'FI') {
            this.strings = {
                'search-label': 'Etsi tuotteistamme',
                'search-short-label': 'Etsi',
                'all-label': 'Kaikki',
                'brand-label': 'Tuotemerkki',
                'designer-label': 'Suunnittelija',
                'length-label': 'Pituus',
                'width-label': 'Leveys / Syvyys',
                'height-label': 'Korkeus',
                'category-label': 'Kategoria',
                'subcategory-label': 'Alakategoria',
                'enviroment-label': 'Ympäristömerkinnät',
                'filter-label': 'Hakuehdot',
                'filter-classic': 'Klassikot',
                'sort-label': 'Hakutulos',
                'sort-standard': 'Standardi',
                'sort-name': 'Tuotteen nimi',
                'sort-new': 'Uusimmat',
                'sort-best': 'Bästa match',
                'search-in-prefix': 'Etsi',
                'search-in-suffix': 'kaikista kategorioista'
            }
        }
    }

    render() {
        return (
            <ReactiveBase
                app="products" url="https://cn-search.inputinterior.se/els/" credentials="elastic:7WsfWWFuStznNFmm"
            >
                <ReactiveComponent
                    componentId="languageFilter"
                    customQuery={props => ({
                        query: {
                            term: {
                                "sprak.raw": this.state.language
                            }
                        },
                        collapse: {
                            "field": "id.raw"
                        },

                    })}

                />


                <div className="wrap container active reactivesearch" role="document">
                    <div className="content row">
                        <main className="main">

                            <div id="algoliaheader"
                                 style={{
                                     backgroundImage: "url(https://www.inputinterior.se/wp-content/uploads/2018/08/header-products-120-2048x834.jpg)",
                                 }}>
                                <div className="aspectholder">
                                    <div className="searcharea">
                                        <div className="searcharea-input">
                                            <CategorySearch
                                                componentId="SearchSensor"
                                                filterLabel={this.strings['search-short-label']}
                                                showClear={true}
                                                showDistinctSuggestions={false}
                                                dataField={['benamn1', 'huvudkategori', 'underkategori', 'underkategori_ngram.raw', 'designer', 'varumarke']}
                                                categoryField="underkategori.raw"
                                                showIcon={false}
                                                URLParams={true}
                                                fieldWeights={[1, 3, 3, 5, 10, 10]}
                                                fuzziness={0}
                                                placeholder={this.strings["search-label"]}
                                                react={{
                                                    and: ['enviromentFilter', 'subCategoryFilter', 'categoryFilter', 'toggleClassic', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor', 'languageFilter', 'brandFilter', 'designerFilter'],
                                                }}
                                                onValueChange={(value) => {
                                                    this.setBestMatchFilterOnChange();
                                                }}
                                                render={({
                                                             loading,
                                                             error,
                                                             value,
                                                             categories,
                                                             suggestions,
                                                             aggregationData,
                                                             downshiftProps: {isOpen, getItemProps, highlightedIndex},
                                                         }) => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    if (error) {
                                                        return <div>Something went wrong! Error
                                                            details {JSON.stringify(error)}</div>;
                                                    }
                                                    return isOpen && Boolean(value.length) ? (
                                                        <div className="search-suggestions">

                                                            {Boolean(value.length) && (
                                                                <div
                                                                    className={0 === highlightedIndex ? 'highlighted' : null}>

                                                                    <div {...getItemProps({
                                                                        item: {
                                                                            label: value,
                                                                            value: value
                                                                        }
                                                                    })}>
                                                                        {this.strings['search-in-prefix']} <strong>{value}</strong> {this.strings['search-in-suffix']}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {suggestions.slice(0, 6).map((suggestion, indexfirst) => (
                                                                <div
                                                                    className={(indexfirst + 1) === highlightedIndex ? 'highlighted' : null}
                                                                    key={suggestion.value} {...getItemProps({item: suggestion})}>
                                                                     <span className="spacer"><span>
                                                                         {this.getHighlightField(suggestion.value, suggestion.source)}</span></span> {suggestion.value}
                                                                </div>
                                                            ))}
                                                            {categories.slice(0, 3).map((category, index) => (
                                                                <div
                                                                    className={((index + 1) + suggestions.slice(0, 6).length) === highlightedIndex ? 'highlighted category-view' : 'category-view'}
                                                                    key={category.key}
                                                                    {...getItemProps({
                                                                        item: {
                                                                            value: value,
                                                                            category: category.key
                                                                        }
                                                                    })}
                                                                >
                                                                    {value} i <strong>{category.key}</strong><span
                                                                    className="spacer"><span>Kategori</span></span>
                                                                </div>
                                                            ))}


                                                        </div>
                                                    ) : null;
                                                }}
                                            />

                                        </div>
                                        <div className="clearFilters"><SelectedFilters showClearAll={false}/></div>


                                        <div className="launchfilter">Filtrera / Sortera</div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="module algoliafilter" data-scroll-scope="">

                        <div className="mobileclose search-close"></div>
                        <div className="modulerow">
                            <div className="modulecol" data-emergence="visible">
                                <div className="algoliafilters">
                                    <div className="filtertabs">
                                        <div className="filtertabs__tab filtertabs__tab--active"
                                             data-group="filtergroup--filtering">Filtrering
                                        </div>
                                        <div className="filtertabs__tab" data-group="filtergroup--sorting">Sortering
                                        </div>
                                    </div>
                                    <div className="filtergroup filtergroup--filtering filtergroup--visible">


                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["brand-label"]}</div>
                                                <MultiDropdownList
                                                    searchPlaceholder={this.strings['search-short-label']}
                                                    componentId="brandFilter"
                                                    dataField="varumarke.raw"
                                                    placeholder={this.strings['all-label']}
                                                    sortBy="asc"
                                                    showSearch={true}
                                                    URLParams={true}
                                                    showCount={false}
                                                    size={150}
                                                    showFilter={true}
                                                    filterLabel={this.strings["brand-label"]}
                                                    react={{
                                                        and: ['enviromentFilter', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'toggleClassic', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor', 'languageFilter', 'designerFilter'],
                                                    }}
                                                />
                                                <div className="amount"><span className="number">
                                                   &nbsp;
                                                </span></div>
                                            </div>
                                        </div>
                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["designer-label"]}</div>
                                                <MultiDropdownList
                                                    searchPlaceholder={this.strings['search-short-label']}
                                                    componentId="designerFilter"
                                                    dataField="designer_poplist.raw"
                                                    placeholder={this.strings['all-label']}
                                                    sortBy="asc"
                                                    URLParams={true}
                                                    showSearch={true}
                                                    showCount={false}
                                                    size={150}
                                                    showFilter={true}
                                                    filterLabel={this.strings["designer-label"]}

                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'brandFilter', 'languageFilter', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor'],
                                                    }}
                                                />
                                                <div className="amount"><span className="number">&nbsp;</span></div>
                                            </div>
                                        </div>

                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["length-label"]}</div>
                                                <RangeSlider
                                                    componentId="LengthSliderSensor"
                                                    dataField="langd"

                                                    filterLabel={this.strings["length-label"]}
                                                    showHistogram={false}
                                                    range={{
                                                        start: 0,
                                                        end: 7000,
                                                    }}
                                                    includeNullValues={true}
                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'brandFilter', 'languageFilter', 'HeightSliderSensor', 'RangeSliderSensor'],
                                                    }}
                                                    tooltipTrigger="hover"
                                                    renderTooltipData={data => (
                                                        <h6 className="toolTip">
                                                            {Math.round(data / 10)} cm
                                                        </h6>
                                                    )}
                                                />
                                                <div className="amount"><span className="number">0 M - 7 M</span></div>

                                            </div>
                                        </div>

                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["width-label"]}</div>

                                                <RangeSlider
                                                    componentId="RangeSliderSensor"
                                                    dataField="bredd_djup"
                                                    filterLabel={this.strings["width-label"]}
                                                    showHistogram={false}
                                                    range={{
                                                        start: 0,
                                                        end: 7000,
                                                    }}
                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'brandFilter', 'languageFilter', 'LengthSliderSensor', 'HeightSliderSensor'],
                                                    }}
                                                    includeNullValues={true}
                                                    tooltipTrigger="hover"
                                                    renderTooltipData={data => (
                                                        <h6 className="toolTip">
                                                            {Math.round(data / 10)} cm
                                                        </h6>
                                                    )}
                                                />
                                                <div className="amount"><span className="number">0 M - 7 M</span></div>
                                            </div>
                                        </div>

                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["height-label"]}</div>
                                                <RangeSlider
                                                    componentId="HeightSliderSensor"
                                                    dataField="hojd"
                                                    filterLabel={this.strings["height-label"]}
                                                    showHistogram={false}
                                                    range={{
                                                        start: 0,
                                                        end: 7000,
                                                    }}
                                                    includeNullValues={true}
                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'brandFilter', 'languageFilter', 'LengthSliderSensor', 'RangeSliderSensor'],
                                                    }}

                                                    tooltipTrigger="hover"
                                                    renderTooltipData={data => (
                                                        <h6 className="toolTip">
                                                            {Math.round(data / 10)} cm
                                                        </h6>
                                                    )}
                                                />
                                                <div className="amount"><span className="number">0 M - 7 M</span></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="filtergroup filtergroup--sorting">

                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings["category-label"]}</div>
                                                <MultiDropdownList
                                                    searchPlaceholder={this.strings['search-short-label']}
                                                    componentId="categoryFilter"
                                                    dataField="huvudkategori.raw"
                                                    placeholder={this.strings['all-label']}
                                                    showSearch={true}
                                                    sortBy="asc"
                                                    showCount={false}
                                                    size={100}
                                                    URLParams={true}
                                                    showFilter={true}
                                                    filterLabel={this.strings["category-label"]}

                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'brandFilter', 'languageFilter', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor',],
                                                    }}
                                                />

                                                <div className="amount"><span className="number">&nbsp;</span></div>
                                            </div>
                                        </div>
                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings['subcategory-label']}</div>
                                                <MultiDropdownList
                                                    searchPlaceholder={this.strings['search-short-label']}
                                                    componentId="subCategoryFilter"
                                                    dataField="underkategori.raw"
                                                    placeholder={this.strings['all-label']}
                                                    showSearch={true}
                                                    sortBy="asc"
                                                    showCount={false}
                                                    size={100}
                                                    URLParams={true}
                                                    showFilter={true}
                                                    filterLabel={this.strings['subcategory-label']}

                                                    react={{
                                                        and: ['enviromentFilter', 'toggleClassic', 'SearchSensor', 'brandFilter', 'categoryFilter', 'languageFilter', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor',],
                                                    }}
                                                />

                                                <div className="amount"><span className="number">&nbsp;</span></div>
                                            </div>
                                        </div>
                                        <div className="filter">
                                            <div className="filter__container">

                                                <div className="label">{this.strings["enviroment-label"]}</div>
                                                <MultiDropdownList

                                                    componentId="enviromentFilter"
                                                    nestedField="enviroment"
                                                    dataField="enviroment.label.raw"
                                                    placeholder={this.strings['all-label']}
                                                    sortBy="asc"
                                                    showSearch={false}
                                                    showCount={false}
                                                    size={150}
                                                    URLParams={true}
                                                    showFilter={true}
                                                    filterLabel={this.strings["enviroment-label"]}
                                                    react={{
                                                        and: ['SearchSensor', 'subCategoryFilter', 'categoryFilter', 'toggleClassic', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor', 'languageFilter', 'designerFilter'],
                                                    }}
                                                />
                                                <div className="amount"><span className="number">&nbsp;</span></div>
                                            </div>
                                        </div>

                                        <div className="filter filter__toggle">

                                            <div className="filter__container">
                                                <div className="label">{this.strings["filter-label"]}</div>
                                                <ToggleButton
                                                    componentId="toggleClassic"
                                                    filterLabel={this.strings["filter-label"]}
                                                    dataField="klassiker.raw"
                                                    URLParams={true}
                                                    data={[
                                                        {label: this.strings["filter-classic"], value: 'J'},
                                                    ]}
                                                />
                                            </div>
                                        </div>


                                        <div className="filter">
                                            <div className="filter__container">
                                                <div className="label">{this.strings['sort-label']}</div>
                                                <Dropdown options={options} onChange={e => this.updateSorting(e)} value={this.state.sortBy} placeholder="Välj sortering" />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="filterbuttons">
                                        <div className="filterbuttons--buttoncol">
                                            <div className="btn btn--small black search-reset">Återställ</div>
                                        </div>
                                        <div className="filterbuttons--buttoncol">
                                            <div className="btn btn--small btn--search">Sök</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ReactiveList
                        dataField={this.state.sortBy.value}
                        componentId="SearchResult"
                        pagination={true}
                        size={30}
                        pages={10}
                        sortBy={this.state.sortOrder}
                        scrollOnChange={false}
                        showResultStats={false}
                        onData={(data) => {
                            this.updateNumberOfResults(data.resultStats.numberOfResults);
                        }}
                        onPageClick={() => {
                            window.scrollTo({
                                top: 650,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }}
                        defaultQuery={props => ({
                            sort: {
                                [this.state.sortBy.value]: {"order": "desc"}
                            },
                        })}
                        react={{
                            and: ['enviromentFilter', 'SearchSensor', 'subCategoryFilter', 'categoryFilter', 'toggleClassic', 'HeightSliderSensor', 'LengthSliderSensor', 'RangeSliderSensor', 'languageFilter', 'brandFilter', 'designerFilter'],
                        }}
                        renderNoResults={() => {
                            return (
                                <div className="no-result">
                                    <div>Din sökning gav <span>ingen träff</span>.</div>
                                    <ul>
                                        <li>Kontrollera att du har stavat korrekt.</li>
                                        <li>Ange mindre specifika sökord.</li>
                                        <li>Prova med ett alternativt ord eller ett synonym.</li>
                                    </ul>


                                   </div>
                            )
                        }}
                        render={({loading, error, data}) => {
                            return (
                                <div className="module algoliaresults">
                                    <div className="modulerow">
                                        {data.map(res => (
                                            <div className="modulecol col-1-5 searchhit" key={res.objectid}
                                                 onClick={function () {
                                                     dispatchModal(res.id)
                                                 }}>
                                                <div className="resultitem" data-id={res.id}
                                                     data-objectid={res.objectid}>
                                                    <div className="resultitem_content">
                                                        <div className="resultitem_content--image"
                                                             style={{backgroundImage: 'url(http://content.inputinterior.se/' + res.huvudbild + '/14)'}}>
                                                            <div className="likebutton" data-id="lh-1212012111000">

                                                            </div>
                                                            <img src="http://content.inputinterior.se/199862/14"
                                                                 alt=""/></div>

                                                        <div className="resultitem_content--info"><h4>{res.benamn1}</h4>
                                                            <p>{res.underkategori}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        }}
                    /></div>
            </ReactiveBase>
        );
    }
}

export default App;
